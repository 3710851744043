import { RouterView } from "vue-router";
// Troubleshoot your routes using this - https://paths.esm.dev

/** @type {import('vue-router').RouterOptions['routes']} */

const names = {
  handlers: "Handlers",
  handlerList: "HandlerList",
  handlerBase: "HandlerBase",
  handlerView: "HandlerView",
  handlerPermits: "HandlerPermits",
  // handlerPermitUnits: "HandlerPermitUnits",
  // handlerFinancialAssurances: "HandlerFinancialAssurances",
  handlerCorrectiveActions: "HandlerCorrectiveActions",
  handlerEvaluations: "HandlerEvaluations",
  handlerViolations: "HandlerViolations",
  handlerEnforcementActions: "HandlerEnforcementActions",
};

export { names as handlerRouteNames };

const handlerRoutes = [
  {
    path: "/handler",
    name: names.handlers,
    component: {
      render: () => <RouterView />,
    },
    redirect: { name: names.handlerList },
    children: [
      {
        path: "list",
        name: names.handlerList,
        meta: { title: "Handlers" },
        component: () => import("@/views/handler/HandlerList.vue"),
      },
      {
        path: ":id(\\d+)",
        name: names.handlerBase,
        component: () => import("@/views/handler/HandlerBase.vue"),
        redirect: { name: names.handlerView },
        children: [
          {
            path: "view",
            name: names.handlerView,
            meta: { title: "Handler View" },
            component: () => import("@/views/handler/HandlerView.vue"),
          },
          {
            path: "permits",
            name: names.handlerPermits,
            meta: { title: "Handler Permits" },
            // component: () => import("@/views/handler/PermitsChildList.vue"),
            component: () => import("@/views/handler/HandlerPermits.vue"),
          },
          // {
          //   path: "permitUnits",
          //   name: names.handlerPermitUnits,
          //   meta: { title: "Handler Permit Units" },
          //   // component: () => import("@/views/handler/PermitUnitsChildList.vue"),
          //   component: () => import("@/views/handler/HandlerPermitUnits.vue"),
          // },
          // {
          //   path: "financialAssurances",
          //   name: names.handlerFinancialAssurances,
          //   meta: { title: "Handler Financial Assurance" },
          //   // component: () => import("@/views/handler/FinancialAssurancesChildList.vue"),
          //   component: () => import("@/views/handler/HandlerFinancialAssurances.vue"),
          // },
          {
            path: "correctiveActions",
            name: names.handlerCorrectiveActions,
            meta: { title: "Handler Corrective Actions" },
            component: () => import("@/views/handler/CorrectiveActionsChildList.vue"),
          },
          {
            path: "evaluations",
            name: names.handlerEvaluations,
            meta: { title: "Handler Evaluations" },
            component: () => import("@/views/handler/EvaluationsChildList.vue"),
          },
          {
            path: "violations",
            name: names.handlerViolations,
            meta: { title: "Handler Violations" },
            component: () => import("@/views/handler/ViolationsChildList.vue"),
          },
          {
            path: "enforcementActions",
            name: names.handlerEnforcementActions,
            meta: { title: "Handler Enforcement Actions" },
            component: () => import("@/views/handler/EnforcementActionsChildList.vue"),
          },
        ],
      },
    ],
  },
];
export default handlerRoutes;
