<template>
  <LayoutAppContent>
    <template v-if="!userRef.isAuthPending">
      <HomePublic v-if="!isAuthenticated && !isInternal" />
      <HomeExternal v-if="isExternalAuthenticated" />
      <HomeInternal v-if="isAuthenticated && isInternal" />
    </template>
  </LayoutAppContent>
</template>

<script lang="ts">
import { useUser } from "@modernary/mui/utils/authentication";

import { computed, defineComponent } from "vue";
import HomeExternal from "@/components/HomeExternal.vue";
import HomePublic from "@/components/HomePublic.vue";
import { LayoutAppContent } from "@modernary/mui/components/layouts";
import HomeInternal from "@/components/HomeInternal.vue";

export default defineComponent({
  setup() {
    const { userRef } = useUser();

    const isInternal = computed(
      () => Boolean(userRef.value?.claims?.some((x: any) => x.type === "role" && x.value === "Internal")), //TODO:TS
    );

    const isAuthenticated = computed(() => userRef.value.isAuthenticated);
    const isExternalAuthenticated = computed(() => isAuthenticated?.value && !isInternal.value);

    return {
      isExternalAuthenticated,
      isInternal,
      userRef,
      isAuthenticated,
    };
  },
  components: {
    LayoutAppContent,
    HomeInternal,
    HomeExternal,
    HomePublic,
  },
});
</script>
