<template>
  <router-link
    :to="linkTo"
    class="rounded-lg bg-white px-4 py-5 shadow sm:p-6 border border-transparent transition-all hover:border-sky-200 hover:bg-sky-100 focus:border-sky-200 focus:bg-sky-100"
  >
    <dt class="truncate text-sm font-medium text-neutral-500">{{ description }}</dt>
    <dd class="mt-1 text-3xl font-semibold tracking-tight text-neutral-900">{{ dataValue }}</dd>
  </router-link>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { RouteLocationRaw } from "vue-router";

// Home content for unauth'd users
export default defineComponent({
  name: "DashboardDataButton",
  props: {
    /** A RouteLocationRaw to specify where the link should go */
    linkTo: {
      type: Object as PropType<RouteLocationRaw>,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    dataValue: {
      type: [String, Number],
      required: false,
    },
  },
  setup() {
    return {};
  },
  components: {},
});
</script>
