<template>
  <div>
    <h3 class="text-base font-semibold leading-6 text-neutral-900">{{ title }}</h3>
    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
      <slot></slot>
    </dl>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// Home content for unauth'd users
export default defineComponent({
  name: "DashboardDataButtonGroup",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {};
  },
  components: {},
});
</script>
