/* 
  Insertion order determines presentation order on the UI. Keep the key equal to the "id". 
  Use the functions to get a new copy of an enum when you intend to modify it (e.g. toggle isInactive)  
*/
/*
  Reserved field names:
  isHiddenInSelect - used to hide options from SelectInput
  isInactive - used to disable an option in SelectInput while keeping selected if that's the current value.
*/

export interface IEnumRecord {
  id: string;
  description: string;
  [key: string]: any;
}

export interface IEnumMap extends Map<string, IEnumRecord> {}

export type IEnums = {
  [Property in keyof typeof enumsCopy]: ReturnType<(typeof enumsCopy)[Property]>;
};

// Access to the methods that generate the enums, useful for modifying the properties on the enums without having to "deep copy" first.
export const enumsCopy = {
  activityResponses,
  correctiveActionEventCodes,
  countyCodes,
  enforcementActionTypes,
  evaluationTypes,
  evaluationViolations,
  federalWasteGeneratorTypes,
  filterFederalWasteGeneratorTypes,
  handlerSourceTypes,
  handlerStatuses,
  mechanismTypes,
  permitStatuses,
  permitUnitStatuses,
  violationRtcQualifiers,
  violationTypes,
};

// TODO: Wrap the function calls in readonly?
// Reusable, "standard" enums.
export const enums = Object.entries(enumsCopy).reduce((enumObj, [key, func]) => {
  enumObj[key as keyof IEnums] = func() as any; // bypass TS stupidity.
  return enumObj;
}, {} as IEnums);

export type ActivityResponse = "Y" | "N" | null;
export function activityResponses() {
  return new Map([
    [
      "Y",
      {
        id: "Y",
        description: "Yes",
      },
    ],
    [
      "N",
      {
        id: "N",
        description: "No",
      },
    ],
    [
      "U",
      {
        id: "U",
        description: "U",
      },
    ],
    [
      null,
      {
        id: null,
        description: "N/A",
      },
    ],
  ]);
}

export type CorrectiveActionEventCode =
  | "CA010"
  | "CA050"
  | "CA060"
  | "CA070"
  | "CA075"
  | "CA100"
  | "CA110"
  | "CA120"
  | "CA140"
  | "CA150"
  | "CA155"
  | "CA160"
  | "CA170"
  | "CA180"
  | "CA190"
  | "CA195"
  | "CA200"
  | "CA210"
  | "CA225"
  | "CA250"
  | "CA260"
  | "CA270"
  | "CA300"
  | "CA305"
  | "CA310"
  | "CA320"
  | "CA330"
  | "CA340"
  | "CA345"
  | "CA350"
  | "CA370"
  | "CA375"
  | "CA380"
  | "CA400"
  | "CA450"
  | "CA500"
  | "CA510"
  | "CA550"
  | "CA555"
  | "CA600"
  | "CA650"
  | "CA725"
  | "CA750"
  | "CA770"
  | "CA772"
  | "CA780"
  | "CA782"
  | "CA800"
  | "CA900"
  | "CA999";
export function correctiveActionEventCodes() {
  return new Map([
    [
      "CA010",
      {
        id: "CA010",
        description: "RFA Initiation",
      },
    ],
    [
      "CA050",
      {
        id: "CA050",
        description: "RFA Completed",
      },
    ],
    [
      "CA060",
      {
        id: "CA060",
        description: "Notice of Contamination",
      },
    ],
    [
      "CA070",
      {
        id: "CA070",
        description: "Determination of Need for an Investigatio",
      },
    ],
    [
      "CA075",
      {
        id: "CA075",
        description: "CA Prioritization",
      },
    ],
    [
      "CA100",
      {
        id: "CA100",
        description: "Investigation Imposition",
      },
    ],
    [
      "CA110",
      {
        id: "CA110",
        description: "Investigation Workplan Received",
      },
    ],
    [
      "CA120",
      {
        id: "CA120",
        description: "Investigation Workplan Modification Requested by Agency",
      },
    ],
    [
      "CA140",
      {
        id: "CA140",
        description: "Investigation Workplan Notice of Deficiency Issued",
      },
    ],
    [
      "CA150",
      {
        id: "CA150",
        description: "Investigation Workplan Approved",
      },
    ],
    [
      "CA155",
      {
        id: "CA155",
        description: "Investigation Supplemental Information Requested by Agency",
      },
    ],
    [
      "CA160",
      {
        id: "CA160",
        description: "Investigation Supplemental Information Received",
      },
    ],
    [
      "CA170",
      {
        id: "CA170",
        description: "Investigation Supplemental Information Deemed Satisfactory",
      },
    ],
    [
      "CA180",
      {
        id: "CA180",
        description: "Investigation Implementation Begun",
      },
    ],
    [
      "CA190",
      {
        id: "CA190",
        description: "Investigation Report Received",
      },
    ],
    [
      "CA195",
      {
        id: "CA195",
        description: "Investigation Progress Reports Received",
      },
    ],
    [
      "CA200",
      {
        id: "CA200",
        description: "Investigation Complete",
      },
    ],
    [
      "CA210",
      {
        id: "CA210",
        description: "CA Responsibility Referred to a Non-RCRA Authority",
      },
    ],
    [
      "CA225",
      {
        id: "CA225",
        description: "Stabilization Measures Evaluation",
      },
    ],
    [
      "CA250",
      {
        id: "CA250",
        description: "CMS Imposition",
      },
    ],
    [
      "CA260",
      {
        id: "CA260",
        description: "CMS Workplan Received",
      },
    ],
    [
      "CA270",
      {
        id: "CA270",
        description: "CMS Workplan Modification Requested by Agency",
      },
    ],
    [
      "CA300",
      {
        id: "CA300",
        description: "CMS Workplan Approved",
      },
    ],
    [
      "CA305",
      {
        id: "CA305",
        description: "CMS Supplemental Information Requested by Agency",
      },
    ],
    [
      "CA310",
      {
        id: "CA310",
        description: "CMS Supplemental Information Received",
      },
    ],
    [
      "CA320",
      {
        id: "CA320",
        description: "CMS Supplemental Information Deemed Satisfactory",
      },
    ],
    [
      "CA330",
      {
        id: "CA330",
        description: "CMS Implementation Begun",
      },
    ],
    [
      "CA340",
      {
        id: "CA340",
        description: "CMS Report Received",
      },
    ],
    [
      "CA345",
      {
        id: "CA345",
        description: "CMS Progress Reports Received",
      },
    ],
    [
      "CA350",
      {
        id: "CA350",
        description: "CMS Complete",
      },
    ],
    [
      "CA370",
      {
        id: "CA370",
        description: "Petition for No Further Action Receipt Date",
      },
    ],
    [
      "CA375",
      {
        id: "CA375",
        description: "Interim Decision for No Further Action",
      },
    ],
    [
      "CA380",
      {
        id: "CA380",
        description: "Date for Public Notice on Proposed Remedy",
      },
    ],
    [
      "CA400",
      {
        id: "CA400",
        description: "Remedy Decision",
      },
    ],
    [
      "CA450",
      {
        id: "CA450",
        description: "Corrective Measures Design Approved",
      },
    ],
    [
      "CA500",
      {
        id: "CA500",
        description: "CMI Workplan Approved",
      },
    ],
    [
      "CA510",
      {
        id: "CA510",
        description: "Determination of Technical Impracticability",
      },
    ],
    [
      "CA550",
      {
        id: "CA550",
        description: "Remedy Construction",
      },
    ],
    [
      "CA555",
      {
        id: "CA555",
        description: "Remedy Construction Deferral Expiration",
      },
    ],
    [
      "CA600",
      {
        id: "CA600",
        description: "Stabilization/Interim Measures Decision",
      },
    ],
    [
      "CA650",
      {
        id: "CA650",
        description: "Stabilization Construction Completed",
      },
    ],
    [
      "CA725",
      {
        id: "CA725",
        description: "Current Human Exposures Under Control Determination",
      },
    ],
    [
      "CA750",
      {
        id: "CA750",
        description: "Groundwater Releases Controlled Determination",
      },
    ],
    [
      "CA770",
      {
        id: "CA770",
        description: "Engineering Controls Established",
      },
    ],
    [
      "CA772",
      {
        id: "CA772",
        description: "Institutional Controls Established",
      },
    ],
    [
      "CA780",
      {
        id: "CA780",
        description: "Engineering Controls Terminated",
      },
    ],
    [
      "CA782",
      {
        id: "CA782",
        description: "Institutional Controls Terminated",
      },
    ],
    [
      "CA800",
      {
        id: "CA800",
        description: "Ready for Anticipated Use",
      },
    ],
    [
      "CA900",
      {
        id: "CA900",
        description: "CA Performance Standards Attained",
      },
    ],
    [
      "CA999",
      {
        id: "CA999",
        description: "Corrective Action Process Terminated",
      },
    ],
  ]);
}

export type CountyCode = "HI001" | "HI003" | "HI005" | "HI007" | "HI009";
export function countyCodes() {
  return new Map([
    [
      "HI001",
      {
        id: "HI001",
        description: "Hawaii",
      },
    ],
    [
      "HI003",
      {
        id: "HI003",
        description: "Honolulu",
      },
    ],
    [
      "HI005",
      {
        id: "HI005",
        description: "Kalawao",
      },
    ],
    [
      "HI007",
      {
        id: "HI007",
        description: "Kauai",
      },
    ],
    [
      "HI009",
      {
        id: "HI009",
        description: "Maui",
      },
    ],
  ]);
}

export type EnforcementActionType =
  | "110"
  | "120"
  | "130"
  | "140"
  | "210"
  | "220"
  | "230"
  | "240"
  | "250"
  | "305"
  | "310"
  | "320"
  | "330"
  | "340"
  | "380"
  | "385"
  | "410"
  | "420"
  | "425"
  | "430"
  | "510"
  | "520"
  | "530"
  | "610"
  | "620"
  | "630"
  | "710"
  | "720"
  | "730"
  | "740"
  | "810"
  | "820"
  | "830"
  | "840"
  | "850"
  | "860"
  | "865";
export function enforcementActionTypes() {
  return new Map([
    [
      "110",
      {
        id: "110",
        description: "Verbal Informal",
      },
    ],
    [
      "120",
      {
        id: "120",
        description: "Written Informal",
      },
    ],
    [
      "130",
      {
        id: "130",
        description: "Notice of Determination",
      },
    ],
    [
      "140",
      {
        id: "140",
        description: "Letter of Intent to Initiate Enforcement Action",
      },
    ],
    [
      "210",
      {
        id: "210",
        description: "Initial 3008(a) Compliance",
      },
    ],
    [
      "220",
      {
        id: "220",
        description: "Initial Imminent and Substantial Endangerment Order",
      },
    ],
    [
      "230",
      {
        id: "230",
        description: "Initial Monitoring, Analysis, Test Order",
      },
    ],
    [
      "240",
      {
        id: "240",
        description: "Initial 3008(h) I.S. CA Order",
      },
    ],
    [
      "250",
      {
        id: "250",
        description: "Field Citation",
      },
    ],
    [
      "305",
      {
        id: "305",
        description: "3008(a) Expedited Settlement Agreement",
      },
    ],
    [
      "310",
      {
        id: "310",
        description: "Final 3008(a) Compliance Order",
      },
    ],
    [
      "320",
      {
        id: "320",
        description: "Final Imminent Hazard Order",
      },
    ],
    [
      "330",
      {
        id: "330",
        description: "Final Monitoring, Analysis, Test Order",
      },
    ],
    [
      "340",
      {
        id: "340",
        description: "Final 3008(h) I.S. CA Order",
      },
    ],
    [
      "380",
      {
        id: "380",
        description: "Multi Site Super CA/FO",
      },
    ],
    [
      "385",
      {
        id: "385",
        description: "Single Site Super CA/FO",
      },
    ],
    [
      "410",
      {
        id: "410",
        description: "Referral to Attorney General",
      },
    ],
    [
      "420",
      {
        id: "420",
        description: "Referral to Department of Justice",
      },
    ],
    [
      "425",
      {
        id: "425",
        description: "Referral to DOJ to Collect Penalties",
      },
    ],
    [
      "430",
      {
        id: "430",
        description: "Referral to District Attorney/City Attorney/County Attorney/State Attorney",
      },
    ],
    [
      "510",
      {
        id: "510",
        description: "Initial Civil/Judicial Action for Compliance and/or Monetary Penalty",
      },
    ],
    [
      "520",
      {
        id: "520",
        description: "Initial Civil Action for Imminent and Substantial Endangerment",
      },
    ],
    [
      "530",
      {
        id: "530",
        description: "Initial Civil/Judicial Action for Corrective Action",
      },
    ],
    [
      "610",
      {
        id: "610",
        description: "Final Civil/Judicial Action for Compliance and/or Monetary Penalty",
      },
    ],
    [
      "620",
      {
        id: "620",
        description: "Final Civil/Judicial Action for Imminent and Substantial Endangerment",
      },
    ],
    [
      "630",
      {
        id: "630",
        description: "Final Civil/Judicial Action for Interim Corrective Action",
      },
    ],
    [
      "710",
      {
        id: "710",
        description: "Referral to Criminal",
      },
    ],
    [
      "720",
      {
        id: "720",
        description: "Criminal Indictment",
      },
    ],
    [
      "730",
      {
        id: "730",
        description: "Criminal Conviction",
      },
    ],
    [
      "740",
      {
        id: "740",
        description: "Criminal Acquittal",
      },
    ],
    [
      "810",
      {
        id: "810",
        description: "State to EPA Administrative Referral",
      },
    ],
    [
      "820",
      {
        id: "820",
        description: "EPA to State Administrative Referral",
      },
    ],
    [
      "830",
      {
        id: "830",
        description: "RCRA to CERCLA Administrative Referral",
      },
    ],
    [
      "840",
      {
        id: "840",
        description: "EPA Regions to EPA HQ Administrative Referral",
      },
    ],
    [
      "850",
      {
        id: "850",
        description: "Administrative Referrals to Other RCRA Programs",
      },
    ],
    [
      "860",
      {
        id: "860",
        description: "Administrative Referrals to Other Programs",
      },
    ],
    [
      "865",
      {
        id: "865",
        description: "Referral to U.S. Treasury",
      },
    ],
  ]);
}

export type EvaluationType =
  | "CAC"
  | "CAV"
  | "CDI"
  | "CEI"
  | "CSE"
  | "FCI"
  | "FRR"
  | "FSD"
  | "FUI"
  | "GME"
  | "NIR"
  | "NRR"
  | "OAM"
  | "SNN"
  | "SNY";
export function evaluationTypes() {
  return new Map([
    [
      "CAC",
      {
        id: "CAC",
        description: "Corrective Action Compliance Evaluation",
      },
    ],
    [
      "CAV",
      {
        id: "CAV",
        description: "Compliance Assistance Visit",
      },
    ],
    [
      "CDI",
      {
        id: "CDI",
        description: "Case Development Inspection",
      },
    ],
    [
      "CEI",
      {
        id: "CEI",
        description: "Compliance Evaluation Inspection",
      },
    ],
    [
      "CSE",
      {
        id: "CSE",
        description: "Compliance Schedule Evaluation",
      },
    ],
    [
      "FCI",
      {
        id: "FCI",
        description: "Focused Compliance Inspection",
      },
    ],
    [
      "FRR",
      {
        id: "FRR",
        description: "Financial Record Review",
      },
    ],
    [
      "FSD",
      {
        id: "FSD",
        description: "Facility Self Disclosure",
      },
    ],
    [
      "FUI",
      {
        id: "FUI",
        description: "Follow-Up Inspection",
      },
    ],
    [
      "GME",
      {
        id: "GME",
        description: "Groundwater Monitoring Evaluation",
      },
    ],
    [
      "NIR",
      {
        id: "NIR",
        description: "No 3007 Information Request Received",
      },
    ],
    [
      "NRR",
      {
        id: "NRR",
        description: "Non-financial Record Review",
      },
    ],
    [
      "OAM",
      {
        id: "OAM",
        description: "Operation and Maintenance Inspection",
      },
    ],
    [
      "SNN",
      {
        id: "SNN",
        description: "No Longer a Significant Non-Complier (SNC)",
      },
    ],
    [
      "SNY",
      {
        id: "SNY",
        description: "A Significant Non-Complier (SNC)",
      },
    ],
  ]);
}

export type EvaluationViolation = "Y" | "N" | "U";
export function evaluationViolations() {
  return new Map([
    [
      "Y",
      {
        id: "Y",
        description: "Yes",
      },
    ],
    [
      "N",
      {
        id: "N",
        description: "No",
      },
    ],
    [
      "U",
      {
        id: "U",
        description: "Undetermined",
      },
    ],
  ]);
}

export type FederalWasteGeneratorTypes = "1" | "2" | "3" | "U" | "N";
export function federalWasteGeneratorTypes() {
  return new Map([
    [
      "1",
      {
        id: "1",
        description: "LQG",
        longDescription: "Large Quantity Generator",
        isFilter: true,
      },
    ],
    [
      "2",
      {
        id: "2",
        description: "SQG",
        longDescription: "Small Quantity Generator",
        isFilter: true,
      },
    ],
    [
      "3",
      {
        id: "3",
        description: "VSQG",
        longDescription: "Very Small Quantity Generator",
        isFilter: true,
      },
    ],
    [
      "U",
      {
        id: "U",
        description: "Undetermined",
        longDescription: "Undetermined",
        isFilter: false,
      },
    ],
    [
      "N",
      {
        id: "N",
        description: "Not Generator",
        longDescription: "Not Generator",
        isFilter: true,
      },
    ],
    [
      "P",
      {
        id: "P",
        description: null,
        longDescription: null,
        isFilter: false,
      },
    ],
  ]);
}

export type FilterFederalWasteGeneratorTypes = "1" | "2" | "3";
export function filterFederalWasteGeneratorTypes() {
  return new Map([...federalWasteGeneratorTypes()].filter(([k, v]) => v.isFilter));
}

export type HandlerSourceType = "A" | "B" | "D" | "E" | "I" | "K" | "N" | "R" | "T";
export function handlerSourceTypes() {
  return new Map([
    [
      "A",
      {
        id: "A",
        description: "Part A",
      },
    ],
    [
      "B",
      {
        id: "B",
        description: "Annual/Biennial Report update with Notification",
      },
    ],
    [
      "D",
      {
        id: "D",
        description: "Deactivation",
      },
    ],
    [
      "E",
      {
        id: "E",
        description: "Emergency",
      },
    ],
    [
      "I",
      {
        id: "I",
        description: "Implementer",
      },
    ],
    [
      "K",
      {
        id: "K",
        description: "Electronic Manifest Broker",
      },
    ],
    [
      "N",
      {
        id: "N",
        description: "Notification",
      },
    ],
    [
      "R",
      {
        id: "R",
        description: "Annual/Biennial Report",
      },
    ],
    [
      "T",
      {
        id: "T",
        description: "Temporary",
      },
    ],
  ]);
}

export type HandlerStatus = "Active" | "Inactive";
export function handlerStatuses() {
  return new Map([
    [
      "Active",
      {
        id: "Active",
        description: "Active",
      },
    ],
    [
      "Inactive",
      {
        id: "Inactive",
        description: "Inactive",
      },
    ],
  ]);
}

export type MechanismType = "B" | "C" | "F" | "I" | "L" | "N" | "P" | "S" | "T" | "X";
export function mechanismTypes() {
  return new Map([
    [
      "B",
      {
        id: "B",
        description: "Surety Bond Guaranteeing Payments to a Trust Fund",
      },
    ],
    [
      "C",
      {
        id: "C",
        description: "Corporate Guarantee",
      },
    ],
    [
      "F",
      {
        id: "F",
        description: "Financial Test",
      },
    ],
    [
      "I",
      {
        id: "I",
        description: "Insurance",
      },
    ],
    [
      "L",
      {
        id: "L",
        description: "Letter of Credit",
      },
    ],
    [
      "N",
      {
        id: "N",
        description: "No Mechanism Established",
      },
    ],
    [
      "P",
      {
        id: "P",
        description: "Trust Fund (Pay-in-Trust)",
      },
    ],
    [
      "S",
      {
        id: "S",
        description: "Surety Bond Guaranteeing Performance",
      },
    ],
    [
      "T",
      {
        id: "T",
        description: "Trust Fund (Fully Funded)",
      },
    ],
    [
      "X",
      {
        id: "X",
        description: "Standby Trust Fund (associated with the surety bond or letter of credit)",
      },
    ],
  ]);
}

export type PermitStatus = "Y" | "N";
export function permitStatuses() {
  return new Map([
    [
      "Y",
      {
        id: "Y",
        description: "Active",
      },
    ],
    [
      "N",
      {
        id: "N",
        description: "Inactive",
      },
    ],
  ]);
}

export type PermitUnitStatus = "Y" | "N";
export function permitUnitStatuses() {
  return new Map([
    [
      "Y",
      {
        id: "Y",
        description: "Active",
      },
    ],
    [
      "N",
      {
        id: "N",
        description: "Inactive",
      },
    ],
  ]);
}

export type ViolationRtcQualifier = "D" | "N" | "U" | "O";
export function violationRtcQualifiers() {
  return new Map([
    [
      "D",
      {
        id: "D",
        description: "Documented",
      },
    ],
    [
      "N",
      {
        id: "N",
        description: "Not Resolvable",
      },
    ],
    [
      "U",
      {
        id: "U",
        description: "Observed",
      },
    ],
    [
      "O",
      {
        id: "O",
        description: "Unverifiable",
      },
    ],
  ]);
}

export type ViolationType =
  | "260.A"
  | "260.B"
  | "260.C"
  | "261.A"
  | "261.AA"
  | "261.B"
  | "261.BB"
  | "261.C"
  | "261.CC"
  | "261.D"
  | "261.E"
  | "261.H"
  | "261.I"
  | "261.J"
  | "261.M"
  | "262.A"
  | "262.B"
  | "262.C"
  | "262.D"
  | "262.E"
  | "262.F"
  | "262.H"
  | "262.K"
  | "262.L"
  | "262.M"
  | "263.A"
  | "263.B"
  | "263.C"
  | "264.A"
  | "264.AA"
  | "264.B"
  | "264.BB"
  | "264.C"
  | "264.CC"
  | "264.D"
  | "264.DD"
  | "264.E"
  | "264.EE"
  | "264.F"
  | "264.FF"
  | "264.G"
  | "264.H"
  | "264.I"
  | "264.J"
  | "264.K"
  | "264.L"
  | "264.M"
  | "264.N"
  | "264.O"
  | "264.S"
  | "264.W"
  | "264.X"
  | "265.A"
  | "265.AA"
  | "265.B"
  | "265.BB"
  | "265.C"
  | "265.CC"
  | "265.D"
  | "265.DD"
  | "265.E"
  | "265.EE"
  | "265.F"
  | "265.FF"
  | "265.G"
  | "265.H"
  | "265.I"
  | "265.J"
  | "265.K"
  | "265.L"
  | "265.M"
  | "265.N"
  | "265.O"
  | "265.P"
  | "265.Q"
  | "265.R"
  | "265.W"
  | "266.C"
  | "266.F"
  | "266.G"
  | "266.H"
  | "266.M"
  | "266.N"
  | "266.P"
  | "267.E"
  | "268.A"
  | "268.B"
  | "268.C"
  | "268.D"
  | "268.E"
  | "270.A"
  | "270.B"
  | "270.C"
  | "270.D"
  | "270.F"
  | "270.G"
  | "270.H"
  | "270.I"
  | "271.A"
  | "273.A"
  | "273.B"
  | "273.C"
  | "273.D"
  | "273.E"
  | "273.F"
  | "273.G"
  | "279.A"
  | "279.B"
  | "279.C"
  | "279.D"
  | "279.E"
  | "279.F"
  | "279.G"
  | "279.H"
  | "279.I"
  | "FEA"
  | "FSS"
  | "PCR"
  | "XXS";
export function violationTypes() {
  return new Map([
    [
      "260.A",
      {
        id: "260.A",
        description: "Hazardous Waste Management System - General",
      },
    ],
    [
      "260.B",
      {
        id: "260.B",
        description: "HW Management System - Definitions",
      },
    ],
    [
      "260.C",
      {
        id: "260.C",
        description: "HW Management System - Rulemaking Petitions",
      },
    ],
    [
      "261.A",
      {
        id: "261.A",
        description: "Listing - General",
      },
    ],
    [
      "261.AA",
      {
        id: "261.AA",
        description: "Listing - Air Emissions Standards - Process Vents",
      },
    ],
    [
      "261.B",
      {
        id: "261.B",
        description: "Listing - Criteria",
      },
    ],
    [
      "261.BB",
      {
        id: "261.BB",
        description: "Listing - Air Emissions Standards - Equipment Leak",
      },
    ],
    [
      "261.C",
      {
        id: "261.C",
        description: "Listing - Characteristics",
      },
    ],
    [
      "261.CC",
      {
        id: "261.CC",
        description: "Listing- Air Emisions Standards- Tanks/Containers",
      },
    ],
    [
      "261.D",
      {
        id: "261.D",
        description: "Listing - Lists of HW",
      },
    ],
    [
      "261.E",
      {
        id: "261.E",
        description: "Listing - Exclusion / Exemptions",
      },
    ],
    [
      "261.H",
      {
        id: "261.H",
        description: "Listing - Financial Requirements",
      },
    ],
    [
      "261.I",
      {
        id: "261.I",
        description: "Listing - Container Use and Management",
      },
    ],
    [
      "261.J",
      {
        id: "261.J",
        description: "Listing - Tank Systems Standards",
      },
    ],
    [
      "261.M",
      {
        id: "261.M",
        description: "Listing - Preparedness and Response",
      },
    ],
    [
      "262.A",
      {
        id: "262.A",
        description: "Generators - General",
      },
    ],
    [
      "262.B",
      {
        id: "262.B",
        description: "Generators - Manifest",
      },
    ],
    [
      "262.C",
      {
        id: "262.C",
        description: "Generators - Pre-transport",
      },
    ],
    [
      "262.D",
      {
        id: "262.D",
        description: "Generators - Records/Reporting",
      },
    ],
    [
      "262.E",
      {
        id: "262.E",
        description: "Generators - Exports",
      },
    ],
    [
      "262.F",
      {
        id: "262.F",
        description: "Generators - Imports",
      },
    ],
    [
      "262.H",
      {
        id: "262.H",
        description: "Generators - Transboundary Shipments for Recovery",
      },
    ],
    [
      "262.K",
      {
        id: "262.K",
        description: "Generators- Academic Labs",
      },
    ],
    [
      "262.L",
      {
        id: "262.L",
        description: "Episodic Generators",
      },
    ],
    [
      "262.M",
      {
        id: "262.M",
        description: "Preparedness, Prevention, and Emergency Procedures",
      },
    ],
    [
      "263.A",
      {
        id: "263.A",
        description: "Transporters - General",
      },
    ],
    [
      "263.B",
      {
        id: "263.B",
        description: "Transporters - Manifest System and Recordkeeping",
      },
    ],
    [
      "263.C",
      {
        id: "263.C",
        description: "Transporters - HW Discharges",
      },
    ],
    [
      "264.A",
      {
        id: "264.A",
        description: "TSD - General",
      },
    ],
    [
      "264.AA",
      {
        id: "264.AA",
        description: "TSD - Air Emission Standards - Process Vents",
      },
    ],
    [
      "264.B",
      {
        id: "264.B",
        description: "TSD - General Facility Standards",
      },
    ],
    [
      "264.BB",
      {
        id: "264.BB",
        description: "TSD - Air Emission Standards for Equipment Leaks",
      },
    ],
    [
      "264.C",
      {
        id: "264.C",
        description: "TSD - Preparedness and Prevention",
      },
    ],
    [
      "264.CC",
      {
        id: "264.CC",
        description: "TSD - Air Emission Standards - Tanks/SI/Containers",
      },
    ],
    [
      "264.D",
      {
        id: "264.D",
        description: "TSD - Contingency Plan and Emergency Procedures",
      },
    ],
    [
      "264.DD",
      {
        id: "264.DD",
        description: "TSD - Containment Building Standards",
      },
    ],
    [
      "264.E",
      {
        id: "264.E",
        description: "TSD - Manifest/Records/Reporting",
      },
    ],
    [
      "264.EE",
      {
        id: "264.EE",
        description: "TSD - Munitions/Explosives Storage",
      },
    ],
    [
      "264.F",
      {
        id: "264.F",
        description: "TSD - Releases from SWMUs",
      },
    ],
    [
      "264.FF",
      {
        id: "264.FF",
        description: "TSD - Fees for eManifest Program",
      },
    ],
    [
      "264.G",
      {
        id: "264.G",
        description: "TSD - Closure/Post-Closure",
      },
    ],
    [
      "264.H",
      {
        id: "264.H",
        description: "TSD - Financial Requirements",
      },
    ],
    [
      "264.I",
      {
        id: "264.I",
        description: "TSD - Container Use and Management",
      },
    ],
    [
      "264.J",
      {
        id: "264.J",
        description: "TSD - Tank System Standards",
      },
    ],
    [
      "264.K",
      {
        id: "264.K",
        description: "TSD - Surface Impoundment Standards",
      },
    ],
    [
      "264.L",
      {
        id: "264.L",
        description: "TSD - Waste Pile Standards",
      },
    ],
    [
      "264.M",
      {
        id: "264.M",
        description: "TSD - Land Treatment Standards",
      },
    ],
    [
      "264.N",
      {
        id: "264.N",
        description: "TSD - Landfill Standards",
      },
    ],
    [
      "264.O",
      {
        id: "264.O",
        description: "TSD - Incinerator Standards",
      },
    ],
    [
      "264.S",
      {
        id: "264.S",
        description: "TSD - Corrective Action for SWMUs",
      },
    ],
    [
      "264.W",
      {
        id: "264.W",
        description: "TSD - Drip Pad Standards",
      },
    ],
    [
      "264.X",
      {
        id: "264.X",
        description: "TSD - Miscellaneous Unit Standards",
      },
    ],
    [
      "265.A",
      {
        id: "265.A",
        description: "TSD IS-Genera",
      },
    ],
    [
      "265.AA",
      {
        id: "265.AA",
        description: "TSD IS-Air Emission Standards - Process Vents",
      },
    ],
    [
      "265.B",
      {
        id: "265.B",
        description: "TSD IS-General Facility Standards",
      },
    ],
    [
      "265.BB",
      {
        id: "265.BB",
        description: "TSD IS-Air Emission Standards - Equipment Leaks",
      },
    ],
    [
      "265.C",
      {
        id: "265.C",
        description: "TSD IS-Preparedness and Prevention",
      },
    ],
    [
      "265.CC",
      {
        id: "265.CC",
        description: "TSD IS-Air Emission Standards - Tank/SI/Container",
      },
    ],
    [
      "265.D",
      {
        id: "265.D",
        description: "TSD IS-Contingency Plan and Emergency Procedures",
      },
    ],
    [
      "265.DD",
      {
        id: "265.DD",
        description: "TSD IS-Containment Building Standards",
      },
    ],
    [
      "265.E",
      {
        id: "265.E",
        description: "TSD IS-Manifest/Records/Reporting",
      },
    ],
    [
      "265.EE",
      {
        id: "265.EE",
        description: "TSD IS-Munitions/Explosives Storage",
      },
    ],
    [
      "265.F",
      {
        id: "265.F",
        description: "TSD IS-Ground-Water Monitoring",
      },
    ],
    [
      "265.FF",
      {
        id: "265.FF",
        description: "TSD IS-Fees for eManifest Program",
      },
    ],
    [
      "265.G",
      {
        id: "265.G",
        description: "TSD IS-Closure/Post-Closure",
      },
    ],
    [
      "265.H",
      {
        id: "265.H",
        description: "TSD IS-Financial Requirements",
      },
    ],
    [
      "265.I",
      {
        id: "265.I",
        description: "TSD IS-Container Use and Management",
      },
    ],
    [
      "265.J",
      {
        id: "265.J",
        description: "TSD IS-Tank System Standards",
      },
    ],
    [
      "265.K",
      {
        id: "265.K",
        description: "TSD IS-Surface Impoundment Standards",
      },
    ],
    [
      "265.L",
      {
        id: "265.L",
        description: "TSD IS-Waste Pile Standards",
      },
    ],
    [
      "265.M",
      {
        id: "265.M",
        description: "TSD IS-Land Treatment Standards",
      },
    ],
    [
      "265.N",
      {
        id: "265.N",
        description: "TSD IS-Landfill Standards",
      },
    ],
    [
      "265.O",
      {
        id: "265.O",
        description: "TSD IS-Incinerator Standards",
      },
    ],
    [
      "265.P",
      {
        id: "265.P",
        description: "TSD IS-Thermal Treatment",
      },
    ],
    [
      "265.Q",
      {
        id: "265.Q",
        description: "TSD IS-Chemical, Physical, AND Treatment",
      },
    ],
    [
      "265.R",
      {
        id: "265.R",
        description: "TSD IS-Underground Injection",
      },
    ],
    [
      "265.W",
      {
        id: "265.W",
        description: "TSD IS-Drip Pad Standards",
      },
    ],
    [
      "266.C",
      {
        id: "266.C",
        description: "Specific - Use Constituting Disposal",
      },
    ],
    [
      "266.F",
      {
        id: "266.F",
        description: "Specific - Precious Metal Recovery",
      },
    ],
    [
      "266.G",
      {
        id: "266.G",
        description: "Specific - Batteries Reclaimed",
      },
    ],
    [
      "266.H",
      {
        id: "266.H",
        description: "Specific - Boilers and Industrial Furnaces",
      },
    ],
    [
      "266.M",
      {
        id: "266.M",
        description: "Specific - Military Munitions",
      },
    ],
    [
      "266.N",
      {
        id: "266.N",
        description: "Specific - Mixed Waste Exemption",
      },
    ],
    [
      "266.P",
      {
        id: "266.P",
        description: "Hazardous Waste Pharmaceuticals",
      },
    ],
    [
      "267.E",
      {
        id: "267.E",
        description: "Manifest System-Recordkeeping, Rpting, Notifying",
      },
    ],
    [
      "268.A",
      {
        id: "268.A",
        description: "LDR - General",
      },
    ],
    [
      "268.B",
      {
        id: "268.B",
        description: "LDR - Schedule",
      },
    ],
    [
      "268.C",
      {
        id: "268.C",
        description: "LDR - Prohibitions",
      },
    ],
    [
      "268.D",
      {
        id: "268.D",
        description: "LDR - Treatment Standards",
      },
    ],
    [
      "268.E",
      {
        id: "268.E",
        description: "LDR - Storage Prohibitions",
      },
    ],
    [
      "270.A",
      {
        id: "270.A",
        description: "Permits - General Information",
      },
    ],
    [
      "270.B",
      {
        id: "270.B",
        description: "Permits - Application",
      },
    ],
    [
      "270.C",
      {
        id: "270.C",
        description: "Permits - Conditions",
      },
    ],
    [
      "270.D",
      {
        id: "270.D",
        description: "Permits - Changes",
      },
    ],
    [
      "270.F",
      {
        id: "270.F",
        description: "Permits - Special Forms",
      },
    ],
    [
      "270.G",
      {
        id: "270.G",
        description: "Permits - Interim Status",
      },
    ],
    [
      "270.H",
      {
        id: "270.H",
        description: "Permits - Remedial Action Plans",
      },
    ],
    [
      "270.I",
      {
        id: "270.I",
        description: "Permits - MACT Standards",
      },
    ],
    [
      "271.A",
      {
        id: "271.A",
        description: "State Authorization Requirements",
      },
    ],
    [
      "273.A",
      {
        id: "273.A",
        description: "Universal Waste - General",
      },
    ],
    [
      "273.B",
      {
        id: "273.B",
        description: "Universal Waste - Small Quantity Handlers",
      },
    ],
    [
      "273.C",
      {
        id: "273.C",
        description: "Universal Waste - Large Quantity Handlers",
      },
    ],
    [
      "273.D",
      {
        id: "273.D",
        description: "Universal Waste - Transporters",
      },
    ],
    [
      "273.E",
      {
        id: "273.E",
        description: "Universal Waste - Destination Facilities",
      },
    ],
    [
      "273.F",
      {
        id: "273.F",
        description: "Universal Waste - Import Requirements",
      },
    ],
    [
      "273.G",
      {
        id: "273.G",
        description: "Universal Waste - Petitions to Include Other Waste",
      },
    ],
    [
      "279.A",
      {
        id: "279.A",
        description: "Used Oil - Definitions",
      },
    ],
    [
      "279.B",
      {
        id: "279.B",
        description: "Used Oil - Applicability",
      },
    ],
    [
      "279.C",
      {
        id: "279.C",
        description: "Used Oil - Generators",
      },
    ],
    [
      "279.D",
      {
        id: "279.D",
        description: "Used Oil - Collection Centers / Aggregation Point",
      },
    ],
    [
      "279.E",
      {
        id: "279.E",
        description: "Used Oil - Transporter and Transfer Facility",
      },
    ],
    [
      "279.F",
      {
        id: "279.F",
        description: "Used Oil - Processors and Re-refiners",
      },
    ],
    [
      "279.G",
      {
        id: "279.G",
        description: "Used Oil - Burners of Off-Spec for Energy Recovery",
      },
    ],
    [
      "279.H",
      {
        id: "279.H",
        description: "Used Oil - Fuel Marketers",
      },
    ],
    [
      "279.I",
      {
        id: "279.I",
        description: "Used Oil - Dust Suppressant and Disposal",
      },
    ],
    [
      "FEA",
      {
        id: "FEA",
        description: "Formal Enforcement Agreement or Order",
      },
    ],
    [
      "FSS",
      {
        id: "FSS",
        description: "Federal or State Statute",
      },
    ],
    [
      "PCR",
      {
        id: "PCR",
        description: "Permit Condition or Requirement",
      },
    ],
    [
      "XXS",
      {
        id: "XXS",
        description: "State Statute or Regulation",
      },
    ],
  ]);
}
